import React from "react";
import { Container, Row, Col } from "reactstrap";
import PageLayout from "../components/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faChessKnight,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const AcercaDeNosotrosPage = () => (
  <PageLayout>
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <h3 className="mt-5 text-center text-uppercase">
            Acerca de Nosotros
          </h3>
          <hr className="title-line" />
          <section className="mt-5">
            <div className="text-center mb-3">
              <FontAwesomeIcon icon={faBuilding} size="3x" color="#BCA068" fixedWidth />
            </div>
            <h4 className="text-center">Nuestra histor&iacute;a</h4>
            <p>
              Desde 1976 venimos trabajando como una de las empresas m&aacute;s
              solidas establecidas y reconocidas de Finca Ra&iacute;z en la
              ciudad de Bogot&aacute;. Siempre conformada por profesionales de
              experiencia, estudio y reconocido prestigio dentro del gremio
              inmobiliario, con el f&iacute;n de prestar la mejor
              asesor&iacute;a a nuestros clientes.
            </p>
          </section>
          <section className="mt-5">
            <div className="text-center mb-3">
              <FontAwesomeIcon icon={faChessKnight} size="3x" color="#BCA068" fixedWidth />
            </div>
            <h4 className="text-center">Misi&oacute;n</h4>
            <p>
              Somos una compañ&iacute;a cuya actividad gira entorno a la
              gesti&oacute;n integral inmobiliaria, desarrollando
              asesor&iacute;a y servicio en el &aacute;rea urbana de la ciudad
              de Bogot&aacute;, brindando a todos nuestros clientes respaldo,
              seguridad y compromiso.
            </p>
          </section>
          <section className="mt-5">
            <div className="text-center mb-3">
              <FontAwesomeIcon icon={faChartLine} size="3x" color="#BCA068" fixedWidth />
            </div>
            <h4 className="text-center">Visi&oacute;n</h4>
            <p>
              Gracias a nuestro <strong>servicio personalizado</strong>, la calidad humana de nuestro equipo, la incorporaci&oacute;n de tecnolog&iacute;a
              y el <strong>mejoramiento de nuestros procesos de gestión y planeación</strong>, para el año 2029 seremos una empresa competitiva,
              logrando as&iacute; un importante <strong>posicionamiento en el sector inmobiliario</strong> en la ciudad de Bogot&aacute;.
            </p>
          </section>
        </Col>
      </Row>
    </Container>
  </PageLayout>
);

export default AcercaDeNosotrosPage;
